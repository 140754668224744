import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "color-tokens"
    }}>{`Color Tokens`}</h1>
    <p>{`Color tokens are the main source of information for naming and cataloging design decisions and front-end alignment regarding colors.`}</p>
    <h2 {...{
      "id": "roles"
    }}>{`Roles`}</h2>
    <p>{`Color roles describe the intention behind the color. `}<br />{`
Aligned with the concepts of color psychology, these dads work to reduce the user's cognitive load when identifying or carrying out an action.`}</p>
    <div id="generated-token-roles" />
    <h2 {...{
      "id": "tokens"
    }}>{`Tokens`}</h2>
    <div id="generated-tokens" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      